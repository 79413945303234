import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from './pages/Home';
import Register from './pages/Register';
import Reserved from './pages/Reserved';
import WaitingListContextProvider from './contexts/WaitingListContextAPI';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';

const router = createBrowserRouter([
	{
		path: '/',
		element: <Home />,
	},
	{
		path: '/register',
		element: <Register />,
		action: async ({ request }) => {
			return await request.formData();
		},
	},
	{
		path: '/reserved',
		element: <Reserved />,
	},
	{
		path: '/terms-of-service',
		element: <Terms />,
	},
	{
		path: '/privacy-policy',
		element: <Privacy />,
	},
]);

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

root.render(
	<React.StrictMode>
		<WaitingListContextProvider>
			<RouterProvider router={router} />
		</WaitingListContextProvider>
	</React.StrictMode>
);
