import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWaitingListContextAPI } from '../contexts/WaitingListContextAPI';
import WaitingPosition from '../components/WaitingPosition';
import Header from '../structures/Header';

const Reserved = (): React.ReactElement => {
	const navigate = useNavigate();
	const { registrationForm, email } = useWaitingListContextAPI();

	useEffect(() => {
		if (registrationForm === null || registrationForm === undefined) {
			navigate('/');
		}
	}, [email, registrationForm, navigate]);

	if (registrationForm === null || registrationForm === undefined) {
		return <></>;
	}

	return (
		<div className="w-full">
			<Header showCenteredBgImage={true} />
			<div className="md:mt-20 h-full">
				<div className="m-auto pb-8 sm:pb-16 md:pb-20 sm:w-[38em] lg:max-w-2xl lg:pb-28 xl:pb-32">
					<div className="flex flex-col px-4 items-center gap-4 w-full h-36">
						<h1 className="text-4xl text-gray-900 text-center font-semibold">
							Teyba! You&apos;re almost ready to get your team execution right.
						</h1>
						<h2 className="mt-4 text-4xl text-gray-900 text-center font-semibold leading-[1.4em]">
							You&apos;re{' '}
							<WaitingPosition position={registrationForm.waitlistPosition} />{' '}
							on the waitlist!
						</h2>

						<h3 className="mt-4 text-gray-500 text-lg font-normal leading-6 text-center">
							We&apos;ll be in touch when your{' '}
							<strong>{registrationForm.workspace}</strong> workspace is ready.
							In the meantime, you can follow our journey on Twitter.
						</h3>
						<div className="flex flex-col items-center sm:flex-row sm:items-start mt-12 p-4 w-full rounded-lg shadow-twitter">
							<div className="flex flex-row items-start gap-3">
								<img
									src="/images/twitter-squadds.png"
									alt="Squadds - twitter account"
									className="w-14"
								/>
								<div className="flex flex-col items-start pl-1 gap-2">
									<div className="font-medium">
										<p className=" text-gray-900">Squaddsapp</p>
										<p className="text-gray-200">@Squaddsapp</p>
									</div>
									<div className="font-normal text-gray-500">
										Creating a place for teams to get execution done
										effectively.
									</div>
								</div>
							</div>
							<a
								href="https://twitter.com/Squaddsapp"
								target={'_blank'}
								className="mt-4 sm:mt-0 flex flex-row justify-center items-center h-12 w-56 ring-2 rounded-lg focus:ring-offset-2 focus:ring-offset-gray-900 focus:ring-indigo-300 bg-theme-100 text-center text-base leading-6 font-normal text-white"
								rel="noreferrer"
							>
								Follow on Twitter
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Reserved;
