import * as React from 'react';

export interface IProps
	extends React.DetailedHTMLProps<
		React.ButtonHTMLAttributes<HTMLButtonElement>,
		HTMLButtonElement
	> {
	children: React.ReactNode;
	width: 'small' | 'medium' | 'large';
}

export default function Button(props: IProps) {
	const { children, width, ...rest } = props;
	let widthStyle = 'md:w-30';
	switch (width) {
		case 'small':
			widthStyle = 'md:w-20';
			break;
		case 'medium':
			widthStyle = 'md:w-30';
			break;
		case 'large':
			widthStyle = 'md:w-40';
			break;
	}

	const classNames = `button ${widthStyle}`;

	return (
		<button className={classNames} {...rest}>
			{children}
		</button>
	);
}
