/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';

const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	timeout: 1000,
	headers: {
		'Content-Type': 'application/json',
	},
});

export const get = async (url: string) =>
	await axiosInstance.get(url).then((res) => res.data);

export const post = async (url: string, data?: any) =>
	await axiosInstance.post(url, data).then((res) => res.data);

export const put = async (url: string, data?: any) =>
	await axiosInstance.put(url, data).then((res) => res.data);

export const del = async (url: string) =>
	await axiosInstance.delete(url).then((res) => res.data);

export default axiosInstance;
