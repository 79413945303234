import { useNavigate } from 'react-router-dom';

import Header from '../structures/Header';
import Link from '../components/Link';
import Input from '../components/Input';
import Label from '../components/Label';
import Button from '../components/Button';
import { useWaitingListContextAPI } from '../contexts/WaitingListContextAPI';
import { isEmailValid } from '../utils';

const Home = (): React.ReactElement => {
	const navigate = useNavigate();
	const { email, updateEmail } = useWaitingListContextAPI();

	const handleJoinSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (isEmailValid(email)) {
			navigate(`/register`);
		}
	};

	return (
		<div className="w-full">
			<Header showJoinButton={true} />
			<div className="relative overflow-hidden bg-white">
				<div className="xl:ml-16">
					<div className="bg-white pb-8 sm:pb-16 md:pb-20 lg:w-full lg:max-w-2xl lg:pb-28 xl:pb-32 float-left">
						<main className="mx-auto max-w-7xl px-4 md:mt-8 lg:px-8">
							<div className="text-left">
								<div>
									<div className="bg-[#111827] rounded-full flex max-w-max mx-0 text-white-base p-1 mb-6">
										<label
											htmlFor="email-address"
											className="bg-blue-600 cursor-pointer rounded-full py-1 px-2 text-xs mr-2 text-center"
										>
											JOIN WAITLIST
										</label>
										<div className="text-xs text-white-base my-auto pr-1">
											<label
												htmlFor="email-address"
												className="cursor-pointer "
											>
												Reserve your workspace on Squadds
											</label>
										</div>
										<div className="my-auto pr-2 hidden sm:block">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 20 20"
												fill="currentColor"
												stroke="#6B7280"
												className="w-4 h-4"
											>
												<path
													fillRule="evenodd"
													d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
													clipRule="evenodd"
												/>
											</svg>
										</div>
									</div>
								</div>

								<h1 className="text-4xl font-bold tracking-tight text-gray-800 sm:text-5xl md:text-[50px] lg:max-w-xl md:leading-[60px]">
									<span className="block xl:inline">
										Every company can reach its full potencial by
									</span>{' '}
									<span className="block text-indigo-600 xl:inline">
										executing
									</span>{' '}
									<span className="block xl:inline">goals effectively. 🎖</span>
								</h1>
								<p className="mt-3 text-base text-gray-500 sm:mx-auto sm:mt-5 sm:max-w-xl sm:text-lg md:mt-5 md:text-xl lg:mx-0">
									We provide the most effective experience for companies who
									want to create and manage their goals.
								</p>
								<div className="mt-5 md:mt-0">
									<form method="post" onSubmit={handleJoinSubmit}>
										<div className="sm:overflow-hidden sm:rounded-md">
											<div className="space-y-6 bg-white py-5 pr-6">
												<Label
													htmlFor="email-address"
													className="block text-base font-medium text-gray-900"
												>
													Sign up to get notified when it’s ready.
												</Label>
												<div className="mt-3 sm:flex sm:flex-column">
													<Input
														id="email-address"
														name="email"
														autoComplete="email"
														value={email}
														onChange={(e) => updateEmail(e.target.value)}
														required
														className={`${
															email.length > 0 && !isEmailValid(email)
																? 'input-error'
																: 'input'
														} sm:text-sm mr-3 md:h-12 md:w-3/5 ml-auto lg:ml-0 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full`}
														placeholder="Email address"
													/>
													<Button
														width="medium"
														type="submit"
														className="button mt-3 sm:mt-0 w-full sm:w-20 mr-auto lg:mr-0"
													>
														Join Waitlist
													</Button>
												</div>
												<p className="mt-2 text-sm text-gray-500">
													Read our{' '}
													<Link to={`privacy-policy`}>Privacy Policy</Link> and{' '}
													<Link to={`terms-of-service`}>Terms of Service</Link>.
												</p>
											</div>
										</div>
									</form>
								</div>
							</div>
						</main>
					</div>
					<img
						src="/images/squadds-home@2x.png"
						className="float-right md:h-[98%] md:w-[98%] lg:pt-8 lg:mr-[-15%] lg:h-[47%] lg:w-[47%] xl:mr-0 xl:h-[44%] xl:w-[44%] "
					/>
				</div>
			</div>
		</div>
	);
};

export default Home;
