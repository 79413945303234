import * as React from 'react';

export interface IProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
	position: number;
}

export default function WaitingPosition(props: IProps) {
	const { position } = props;

	return (
		<span className="border-2 border-blue-600 font-medium text-blue-600 text-4xl rounded-full py-2 px-3">
			#0{position}
		</span>
	);
}
