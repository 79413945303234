import * as React from 'react';

export interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
	error?: boolean;
}

export default function Input(props: IProps) {
	const { ...rest } = props;

	return <input {...rest} />;
}
