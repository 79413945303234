import { RadioGroup } from '@headlessui/react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useWaitingListContextAPI } from '../contexts/WaitingListContextAPI';
import Header from '../structures/Header';
import Loading from '../components/Loading';
import { IRegisterFormData } from '../models/interfaces';
import { countryCodes } from '../models/countryCodes';
import { get, post } from '../data/fetcher';

const Register = (): React.ReactElement => {
	const navigate = useNavigate();
	const { email, updateRegistrationForm } = useWaitingListContextAPI();
	const [plan, setPlan] = useState('1-25');
	const [isSubmitting, setIsSubmitting] = useState(false);
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<IRegisterFormData>({ defaultValues: { country: 'US' } });

	useEffect(() => {
		if (email.length === 0) {
			navigate('/');
		}
	}, [email, navigate]);

	const onSubmit = handleSubmit(async (data) => {
		setIsSubmitting(true);

		const formData: IRegisterFormData = {
			...data,
			workspace: data.workspace.toLowerCase(),
			email,
			plan,
		};

		try {
			const result = await post('/waitlist/registration', formData);
			formData.waitlistPosition = result.id;
			void updateRegistrationForm(formData);
			// wait 1/2 second before redirecting to the next page
			setTimeout(() => {
				setIsSubmitting(false);
				navigate('/reserved');
			}, 500);
		} catch (error) {
			console.log(error);
		}
	});

	const workspaceErrorClassname =
		errors.workspace != null ? 'border-red-600 focus:border-red-600' : '';
	const phoneNumberErrorClassname =
		errors.phonenumber != null ? 'border-red-600 focus:border-red-600' : '';

	return (
		<div className="w-full">
			<Header showCenteredBgImage={true} />
			<div className="relative md:mt-20 h-full">
				<div className="m-auto px-4 pb-8 sm:pb-16 md:pb-20 sm:w-[32em] lg:max-w-2xl lg:pb-28 xl:pb-32">
					<div className="flex flex-col items-center gap-4 w-full h-36">
						<h1 className="text-4xl text-gray-900 text-center font-semibold">
							Reserve your workspace on Squadds 🍿
						</h1>
						<h2 className="mt-4 text-gray-500 text-lg font-normal leading-6 text-center">
							Get early access and reserve your company workspace to use Squadds
							when it&apos;s ready. 🎖
						</h2>
						{/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
						<form className="py-12  sm:w-full" onSubmit={onSubmit}>
							<div>
								<div className="">
									<div className="grid grid-cols-3">
										<div className="col-span-3 sm:col-span-3">
											<label
												htmlFor="email"
												className="block text-sm font-medium text-gray-700"
											>
												Email
											</label>
											<div className="mt-1 flex rounded-md shadow-sm h-11">
												<span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-sm text-gray-400">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 20 20"
														fill="currentColor"
														className="w-4 h-4"
													>
														<path d="M3 4a2 2 0 00-2 2v1.161l8.441 4.221a1.25 1.25 0 001.118 0L19 7.162V6a2 2 0 00-2-2H3z" />
														<path d="M19 8.839l-7.77 3.885a2.75 2.75 0 01-2.46 0L1 8.839V14a2 2 0 002 2h14a2 2 0 002-2V8.839z" />
													</svg>
												</span>
												<input
													type="text"
													disabled={true}
													name="email"
													id="email"
													className="block rounded-none border rounded-r-md border-l-0 w-full border-gray-300 sm:text-sm focus:border-gray-300 focus:ring-0"
													placeholder="workspace"
													value={email}
												/>
											</div>
										</div>
									</div>

									<div className="grid grid-cols-3 mt-6">
										<div className="col-span-3 sm:col-span-3">
											<label
												htmlFor="company-website"
												className="block text-sm font-medium text-gray-700"
											>
												Workspace URL
											</label>
											<div className="mt-1 flex rounded-md shadow-sm h-11">
												<span
													className={`inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-sm text-gray-500 ${workspaceErrorClassname}`}
												>
													https://
												</span>
												<input
													type="text"
													id="company-website"
													className={`block pl-1 pr-0 text-right rounded-none border border-r-0 sm:min-w-[20em] border-gray-300 sm:text-sm focus:border-gray-300 focus:ring-0 lowercase ${workspaceErrorClassname}`}
													placeholder="workspace"
													{...register('workspace', {
														validate: {
															required: (value) => {
																return (
																	value.length > 0 ||
																	'You must specify a workspace name'
																);
															},
															minLength: (value) => {
																return (
																	value.length >= 5 || 'at least 5 characters'
																);
															},
															checkUrl: async (value) => {
																const lowerCaseValue = value.toLowerCase();
																if (lowerCaseValue === 'squadds') {
																	return `"${lowerCaseValue}" workspace is not available`;
																}

																const result = await get(
																	`/waitlist/check-workspace/${lowerCaseValue}`
																);

																if (result?.available === false) {
																	return `"${lowerCaseValue}" workspace is not available`;
																}
															},
															pattern: (value) => {
																// check value from regex
																return (
																	/^[a-z0-9]+(?:-[a-z0-9]+)*$/i.test(value) ||
																	'just lower-case, numbers and dashes'
																);
															},
														},
													})}
												/>
												<div
													className={`rounded-none pr-1 pt-[9px] sm:pt-[11px] border rounded-r-md align-baseline border-l-0 w-full border-gray-300 sm:text-sm ${workspaceErrorClassname}`}
												>
													.squadds.co
												</div>
											</div>
											{errors.workspace != null && (
												<div className="text-red-600 text-xs">
													{errors.workspace.message}
												</div>
											)}
										</div>
									</div>

									<div className="grid grid-cols-3 mt-6">
										<div className="col-span-3 sm:col-span-3">
											<label
												htmlFor="phone-number"
												className="block text-sm font-medium text-gray-700"
											>
												Phone number
											</label>
											<div className="mt-1 flex rounded-md shadow-sm h-11">
												<span
													className={`inline-flex items-center rounded-l-md border border-r-0 border-gray-300 text-sm text-gray-500 ${phoneNumberErrorClassname}`}
												>
													<select
														id="country"
														className="flex-shrink-0 z-10 items-center border-0 focus:border-0 hover:border-0 text-sm font-normal text-center text-gray-500 focus:outline-none focus:border-gray-300 focus:ring-0"
														{...register('country', {
															required: true,
														})}
													>
														{countryCodes.map((country) => (
															<option key={country.code} value={country.code}>
																{country.code}
															</option>
														))}
													</select>
												</span>
												<input
													type="text"
													id="phone-number"
													className={`block pl-1 rounded-none border rounded-r-md border-l-0 w-full border-gray-300 sm:text-sm focus:border-gray-300 focus:ring-0 ${phoneNumberErrorClassname}`}
													placeholder="(555) 987-6543"
													{...register('phonenumber', {
														required:
															'We need your phone number to check your identity',
														minLength: 6,
													})}
												/>
											</div>
											{errors.phonenumber != null && (
												<div className="text-red-600 text-xs">
													{errors.phonenumber.message}
												</div>
											)}
										</div>
									</div>

									<div className="grid grid-cols-3 mt-6">
										<div className="col-span-3 sm:col-span-3">
											<RadioGroup value={plan} onChange={setPlan}>
												<RadioGroup.Label className="text-sm font-medium text-gray-700">
													Team size
												</RadioGroup.Label>
												<div className="grid grid-cols-3 pt-1">
													<RadioGroup.Option
														value="1-25"
														className={`bg-white shadow-sm text-gray-900 cursor-pointer group relative border-x-0 rounded-l-md py-3 flex items-center justify-center text-sm font-medium uppercase focus:outline-none sm:flex-1 ${
															plan === '1-25'
																? 'bg-gray-100'
																: 'hover:bg-gray-50'
														}`}
													>
														<RadioGroup.Label as="span">
															1 - 25
														</RadioGroup.Label>
														<span
															aria-hidden="true"
															className="border border-gray-300 pointer-events-none absolute -inset-px rounded-l-md"
														/>
													</RadioGroup.Option>
													<RadioGroup.Option
														value="25-100"
														className={`bg-white shadow-sm text-gray-900 cursor-pointer group relative border-x-0 rounded-l-md py-3 flex items-center justify-center text-sm font-medium uppercase focus:outline-none sm:flex-1 ${
															plan === '25-100'
																? 'bg-gray-100'
																: 'hover:bg-gray-50'
														}`}
													>
														<RadioGroup.Label as="span">
															25 - 100
														</RadioGroup.Label>
														<span
															aria-hidden="true"
															className="border border-gray-300 pointer-events-none absolute -inset-px"
														/>
													</RadioGroup.Option>
													<RadioGroup.Option
														value="100+"
														className={`bg-white shadow-sm text-gray-900 cursor-pointer group relative border-x-0 rounded-l-md py-3 flex items-center justify-center text-sm font-medium uppercase focus:outline-none sm:flex-1 ${
															plan === '100+'
																? 'bg-gray-100'
																: 'hover:bg-gray-50'
														}`}
													>
														<RadioGroup.Label as="span">100+</RadioGroup.Label>
														<span
															aria-hidden="true"
															className="border border-gray-300 pointer-events-none absolute -inset-px rounded-r-md"
														/>
													</RadioGroup.Option>
												</div>
											</RadioGroup>
										</div>
									</div>

									<div className="grid grid-cols-3 mt-6">
										<div className="flex col-span-3 sm:col-span-3">
											<label
												htmlFor="default-toggle"
												className="inline-flex relative cursor-pointer"
											>
												<input
													type="checkbox"
													id="default-toggle"
													{...register('checkbox', { required: true })}
													className="sr-only peer"
												/>
												<div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-gray-300 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-gray-600"></div>
											</label>
											<span
												className={`ml-3 text-sm font-normal ${
													errors.checkbox != null ? 'text-red-500' : ''
												}`}
											>
												By selecting this, you agree to the{' '}
												<Link
													className="font-medium underline"
													to={'/privacy-policy'}
													target="_blank"
												>
													Privacy Policy
												</Link>{' '}
												and{' '}
												<Link
													className="font-medium underline"
													to={'/terms-of-service'}
													target="_blank"
												>
													Terms of Service
												</Link>
												.
											</span>
										</div>
									</div>
								</div>

								<div className="mt-8">
									<button
										type="submit"
										disabled={isSubmitting}
										className="h-12 w-full justify-center rounded-md border border-transparent bg-gray-600 text-base font-medium leading-6 text-white shadow-sm hover:bg-gray-700"
									>
										{isSubmitting && <Loading />}
										Reserve workspace
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Register;
